(function () {
    'use strict';

    const practitionerStatus = {
        bindings: {
            practitioner: '=',
            showText: '='
        },
        controller: PractitionerStatusController,
        controllerAs: 'vm',
        templateUrl: 'app/components/practitioner-status.html'
    };

    angular
        .module('continuumplatformApp')
        .component('practitionerStatus', practitionerStatus);

    PractitionerStatusController.$inject = [];

    function PractitionerStatusController() {

        const vm = this;

        vm.$onInit = function () {
            const today = moment().format('yyyy-MM-DD');
            vm.isPractitionerAway = (vm.practitioner.leaveFrom && vm.practitioner.leaveTo >= today) || (vm.practitioner.leaveFrom && !vm.practitioner.leaveTo);
        };

    }

})();

