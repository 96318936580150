(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyMedicationRemoveDialogController', PatientTherapyMedicationRemoveDialogController);

    PatientTherapyMedicationRemoveDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'medication'];

    function PatientTherapyMedicationRemoveDialogController($timeout, $scope, $stateParams, $uibModalInstance, medication) {
        const vm = this;

        vm.medication = medication;

        vm.remove = () => {
            $uibModalInstance.close('confirm');
        }

        vm.clear = () => {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
