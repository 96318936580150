(function() {
    'use strict';

    const visitSchedule = {
        bindings: {
            visit: '=',
            showLastReminderSentDate: '=',
            showNextReminderSentDate: '=',
        },
        controller: VisitScheduleController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-schedule.html'
    };

    angular
        .module('continuumplatformApp')
        .component("visitSchedule", visitSchedule);

    VisitScheduleController.$inject = [];

    function VisitScheduleController() {
        const vm = this;

        vm.$onInit = () => {
            const now = new Date();
            vm.isPlannedDateInPast = vm.visit.plannedDate && moment(vm.visit.plannedDate).isBefore(now);

            vm.showLastReminderSentDateValue = vm.visit.lastReminderSentDate
            && vm.visit.status === 'TODO'
            && (angular.isDefined(vm.showLastReminderSentDate) ? vm.showLastReminderSentDate : true);

            vm.showNextReminderSentDateValue = vm.visit.nextReminderDate
            && moment(vm.visit.nextReminderDate).isAfter(now)
            && vm.visit.status === 'TODO'
            && vm.visit.expectedNow
            && (angular.isDefined(vm.showNextReminderSentDate) ? vm.showNextReminderSentDate : true);
        };

    }
})();
